import React from 'react';
import { classes, st } from './actionBlocker.st.css';
import { Modal } from '../Modal';
import { Tooltip } from 'wix-ui-tpa/Tooltip';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { isForcedPreview } from '../../selectors/isForcedPreview';
import { LocationContext } from '../../contexts/Location/LocationContext';

export enum ActionBlockerAppearance {
  ModalAppearance = 'Modal',
  PopoverAppearance = 'Popover',
}
export interface IPreviewActionBlockerProps {
  blockerContent: React.ReactNode;
  appearance?: ActionBlockerAppearance;
  onActionBlocked?(): void;
  isActionDisabled: boolean;
  className?: string;
}

export const ActionBlocker: React.FC<IPreviewActionBlockerProps> = (props) => {
  const [isWarningShown, toggleWarningModal] = React.useState<boolean>(false);
  const { isPreview } = useEnvironment();
  const onClose = React.useCallback(() => {
    toggleWarningModal(false);
  }, []);
  const { query } = React.useContext(LocationContext);

  if (!isPreview && !isForcedPreview(query)) {
    return <>{props.children}</>;
  }

  return (
    <>
      <Tooltip
        disabled={
          props.appearance !== ActionBlockerAppearance.PopoverAppearance ||
          !props.isActionDisabled
        }
        content={props.blockerContent}
        appendTo="window"
        maxWidth={250}
      >
        <div
          className={st(
            classes.root,
            {
              actionDisabled: props.isActionDisabled,
            },
            props.className,
          )}
          onClick={() => {
            if (props.isActionDisabled) {
              if (
                props.appearance === ActionBlockerAppearance.ModalAppearance
              ) {
                toggleWarningModal(true);
              }
              props.onActionBlocked && props.onActionBlocked();
            }
          }}
        >
          {props.children}
        </div>
      </Tooltip>
      {props.appearance === ActionBlockerAppearance.ModalAppearance && (
        <Modal opened={isWarningShown} onClose={onClose}>
          <div className={classes.warning}>{props.blockerContent}</div>
        </Modal>
      )}
    </>
  );
};

ActionBlocker.defaultProps = {
  appearance: ActionBlockerAppearance.ModalAppearance,
};
