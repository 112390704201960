import React from 'react';

export interface ILeaveChallengeModalContext {
  showModal(
    opts: { participantId: string; challengeId?: string },
    onClose?: () => void,
    onLeave?: () => void,
  ): void;
}

export const LeaveChallengeModalContext = React.createContext<ILeaveChallengeModalContext>(
  {
    showModal: null,
  },
);

export const useLeaveChallengeModal = () =>
  React.useContext(LeaveChallengeModalContext);
