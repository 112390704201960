import React from 'react';
import ReactDOM from 'react-dom';

import { CloseIcon } from './Close';

import { st, classes } from './Modal.st.css';
import { ButtonNames } from '../../contexts/main/biInterfaces';
import { useEnvironment, useBi } from '@wix/yoshi-flow-editor';
import { memberWebAppButtonClick as memberWebAppButtonClickV2 } from '@wix/bi-logger-challenges-member-web/v2';

export interface IModalProps {
  opened?: boolean;
  onClose(): void;
  className?: string;
  contentClassName?: string;
  sticky?: boolean;
  closeAriaLabel?: string;
}

export const Modal: React.FC<IModalProps> = (props) => {
  const { isMobile } = useEnvironment();
  const modalRef = React.useRef<HTMLDivElement>();
  const closeButtonRef = React.useRef<HTMLButtonElement>();
  const el = React.useRef<HTMLDivElement>();
  const [, setMounted] = React.useState(false);

  const { className, sticky = false, contentClassName } = props;

  const bi = useBi();

  React.useEffect(() => {
    el.current = document.createElement('div');

    const $modalRoot = document.getElementById('modal-root');
    $modalRoot?.appendChild(el.current);

    // to force update
    setMounted(true);

    return () => {
      $modalRoot?.removeChild && $modalRoot?.removeChild(el.current);

      document.body.style.overflow = 'inherit';
    };
  }, []);

  React.useEffect(() => {
    if (props.opened) {
      modalRef?.current?.focus();
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'inherit';
    }
  }, [props.opened]);

  const handleClose = async () => {
    const { onClose } = props;

    await bi.report(
      memberWebAppButtonClickV2({
        buttonName: ButtonNames.CloseModalButton,
      }),
    );

    document.body.style.overflow = 'inherit';

    onClose();
  };

  const handleFocusTrap = () => {
    if (closeButtonRef) {
      closeButtonRef?.current?.focus();
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Esc' || e.key === 'Escape') {
      handleClose();
    }
  };

  // this line of code fixes crash of the app when modal is showing up on initial render
  if (!el?.current) {
    return null;
  }

  const modalContent = (
    <div
      id="modal"
      ref={modalRef}
      role="modal"
      tabIndex={-1}
      aria-modal="true"
      onKeyDown={onKeyDown}
    >
      <div className={classes.overlay} onClick={handleClose} />
      <section
        className={st(
          classes.root,
          { mobile: isMobile, sticky, bottomRelated: isMobile },
          className,
        )}
      >
        <div className={classes.header}>
          <button
            aria-label={props.closeAriaLabel || 'Close modal'}
            ref={closeButtonRef}
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </button>
        </div>
        <div className={`${classes.content} ${contentClassName || ''}`}>
          {props.children}
        </div>

        {/* bloody hell trick for focus trap inside the modal */}
        <input
          type="text"
          style={{ opacity: 0, height: 0 }}
          aria-hidden={true}
          onFocus={handleFocusTrap}
        />
      </section>
    </div>
  );

  return props.opened && ReactDOM.createPortal(modalContent, el.current);
};
