import { Participant } from '@wix/ambassador-challenges-v1-participant/types';

export function getCompletionProgress(participant: Participant) {
  const stepsSummary = participant?.stepsSummary;
  if (!stepsSummary) {
    return 0;
  }
  return Math.floor(
    (stepsSummary.completedStepsNumber / stepsSummary.stepsNumber) * 100,
  );
}
