import React from 'react';
import { Reward } from '@wix/ambassador-challenges-v1-challenge/types';
import { useExperiments } from '@wix/yoshi-flow-editor';

import { st, classes } from './Badges.st.css';
import { Spinner } from '../Spinner';
import { Challenges } from '../../editor/types/Experiments';
import { Badge } from '@wix/ambassador-badges-v2-badge/types';
import { useBadges } from '../../contexts/Badges/BadgesContext';

// TODO: shall be deprecated once experiment enableServerlessChallenge is finished
export interface IBadge {
  id: string;
  title: string;
  description: string;
  backgroundColor: string;
  textColor: string;
  icon: string;
  roleId: string;
  hasPermissions: boolean;
  slug: string;
  createDate: string;
  updateDate: string;
}

export enum ALIGNMENT {
  left = 'left',
  right = 'right',
  center = 'center',
}

export interface IBadgesProps {
  badges: Badge[];
  // TODO: 'rewards' and 'instance' shall be deprecated once experiment enableServerlessChallenge is finished
  rewards: Reward[];
  instance: string;
  title?: React.ReactNode;
  alignment?: ALIGNMENT;
}

export const AwardsBadges: React.FC<IBadgesProps> = (props) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const { badges, requestBadges } = useBadges();
  const { ready: isExperimentReady, experiments } = useExperiments();

  const isServerlessChallengeExperimentEnabled = experiments.enabled(
    Challenges.enableServerlessChallenge,
  );

  React.useEffect(() => {
    if (isExperimentReady && isServerlessChallengeExperimentEnabled) {
      // early return to not fetch badges separately but use them from serverless challenges.api
      return;
    }

    const challengeRewardsIds = props.rewards[0]?.badgeIds || null;

    if (!challengeRewardsIds) {
      return;
    }

    setLoading(true);

    const fetchBadges = async () => {
      await requestBadges(challengeRewardsIds);
      setLoading(false);
    };

    fetchBadges();
  }, []);

  const badgesArr: (Badge | IBadge)[] = isServerlessChallengeExperimentEnabled
    ? props.badges
    : badges;

  return (
    <div
      className={st(classes.root, { align: props.alignment || ALIGNMENT.left })}
    >
      {!!badgesArr.length && props.title}
      {loading || !isExperimentReady ? (
        <Spinner role="element" className={classes.spinner} />
      ) : (
        <div className={classes.badgeList}>
          {badgesArr.map((badge, idx) => (
            <span
              key={`${badge.title}-${idx}`}
              className={classes.badge}
              style={{
                color: badge.textColor,
                background: badge.backgroundColor,
              }}
            >
              {!!badge.icon && (
                <img
                  className={classes.image}
                  src={badge.icon}
                  alt={badge.slug}
                />
              )}
              <span className={classes.text}>{badge.title}</span>
            </span>
          ))}
        </div>
      )}
      {}
    </div>
  );
};
