import React from 'react';

import {
  LeaveChallengeModalContext,
  ILeaveChallengeModalContext,
} from './LeaveChallengeModalContext';
import { LeaveChallengeModal } from '../../components-shared/LeaveChallengeModal';

export interface ILeaveChallengeModalProviderProps
  extends ILeaveChallengeModalContext {}

export const LeaveChallengeModalProvider: React.FC<ILeaveChallengeModalProviderProps> = (
  props,
) => {
  const [opened, setOpened] = React.useState<boolean>(false);
  const [participantId, setParticipantId] = React.useState<string>(null);
  const [onClose, setOnClose] = React.useState<() => void>(() => () => {});
  const [onLeave, setOnLeave] = React.useState<() => void>(() => () => {});
  const [challengeId, setChallengeId] = React.useState<string>(null);

  return (
    <LeaveChallengeModalContext.Provider
      value={{
        showModal: (opts, _onClose, _onLeave) => {
          setOpened(true);
          setParticipantId(opts.participantId);
          setOnClose(() => () => {
            if (_onClose) {
              _onClose();
            }
            setOpened(false);
          });
          setOnLeave(() => () => {
            if (_onLeave) {
              _onLeave();
            }
          });
          opts.challengeId && setChallengeId(opts.challengeId);
        },
      }}
    >
      <LeaveChallengeModal
        isOpen={opened}
        participantId={participantId}
        challengeId={challengeId}
        onClose={onClose}
        onLeave={onLeave}
      />
      {props.children}
    </LeaveChallengeModalContext.Provider>
  );
};
